import { useState } from "react";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { BsArrowsFullscreen } from "react-icons/bs";
import Iframe from "react-iframe";

interface CamBox {
  url: string;
  name: string;
}

const CamBoxComponent: React.FC<CamBox> = ({ url, name }) => {
  const [zoom, setZoom] = useState(false);

  return (
    <div className={`${!zoom ? "card-M" : "card-M zoomCard"}`}>
      <div>
        <Iframe
          key={url}
          url={`https://sp01.br.services.congrebio.solucoes.me/fl/video?video=${url}`}
          id="myId"
          className="myClassname"
          position="relative"
          frameBorder={0}
          width="100%"
          height={`${!zoom ? "400px" : "100%"}`}
          sandbox="allow-scripts"
        />
      </div>
      <div>
       {!zoom ? <BsArrowsFullscreen size={30} onClick={() => {setZoom(true)}} /> : <AiOutlineFullscreenExit  size={30}  onClick={() => {setZoom(false)}} />  }
        {name}
      </div>
    </div>
  );
};

export default CamBoxComponent;
