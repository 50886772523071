import HeaderComponent from "../../components/global/header";
import DetailsProcessingComponent from "../../components/processing/details";
import ImageProcessingComponent from "../../components/processing/image";
import "../../styles/pages/lab/processing.scss";
import socketIOClient from "socket.io-client";
import { useEffect, useState } from "react";
const ENDPOINT = "https://sp01.br.services.congrebio.solucoes.me:4049";

const socket = socketIOClient(ENDPOINT, {
  auth: {
    token: localStorage.getItem("@blue"),
  },
});

socket.on("connection", () => {
  console.log("Conectado");
});

interface listProcessing{
  url: string;
}

function LabDashBoard() {

  const [listProcessing, setListProcessing] = useState<listProcessing[]>([])

  const [url, setUrl] = useState("")

  
  useEffect(() => {
    socket.on("imageProcessing", (data) => {
      if(data.data)
      setUrl(data.data.url)
    });
  }, []);

  useEffect(() => {
    socket.on("imageListProcessing", (data) => {
      setListProcessing(data.ar.data)
    });
  }, []);

  return (
    <div className="processing">
      <HeaderComponent />

      <div className="grid2-1">
        <ImageProcessingComponent url={url} />

        <DetailsProcessingComponent list={[listProcessing]} />
      </div>
    </div>
  );
}

export default LabDashBoard;
