import { CgSearchFound } from "react-icons/cg";
import Iframe from "react-iframe";
import HeaderComponent from "../../components/global/header";
import PingAndStatus from "../../components/status/pingAndStatus";

function StatusPage() {
  return (
    <div>
      <HeaderComponent />

      <div className="grid3-mix">
        <div className="card-M listSensors">
          Error Reports <br />
          <CgSearchFound size={40} />
        </div>
        <PingAndStatus />

        <div>
          <Iframe
            url={`https://www.cloudflarestatus.com/`}
            id="myId"
            className="myClassname"
            position="relative"
            frameBorder={0}
            width="100%"
            height={`100%`}
            sandbox="allow-scripts"
          />
        </div>

      </div>
    </div>
  );
}

export default StatusPage;
