import React, { FormEvent, useEffect, useState } from "react";
import "../../styles/pages/login.scss";
import { FaIndustry } from "react-icons/fa";
import socketIOClient from "socket.io-client";
import { useHistory } from "react-router";
const ENDPOINT = "http://127.0.0.1:4000";

function AuthPageRouter() {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.emit("select_rd", {
      room: "java",
      username: "ola",
      socket_id: "2132",
    });
  }, []);

  function emailS(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      window.location.href = `https://my.solucoes.me/ocean/congrebio/auth?domain=https://ic.gutierrez.dev.br/authback&site=ICPlataform&email=${email}`;
    }, 5000);
  }

  useEffect(() => {
    if (localStorage.getItem("@blue")) {
      setLoading(true);
      setTimeout(() => {
        history.push("/dashboard");
      }, 5000);
    }
  }, [history]);

  return (
    <div id="Auth_Page">
      <div className="auth" style={loading ? { display: "flex" } : {}}>
        {!loading && (
          <div className="welcome">
            <h1>Bem Vindo,</h1>
            <h3>IC Plataform</h3>
          </div>
        )}
        <form
          className={`authGo ${loading ? "authTransition" : ""}`}
          onSubmit={emailS}
        >
          <FaIndustry size={40} color="white" />
          {!loading && (
            <>
              <p>Acesso a plataforma</p>
              <input
                type="email"
                placeholder="Digite seu e-mail"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
              <button type="submit">ACESSAR</button>
            </>
          )}

          {loading && <p>Carregando...</p>}
        </form>
      </div>
    </div>
  );
}

export default AuthPageRouter;
