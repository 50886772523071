import HeaderComponent from "../../components/global/header";
import SensorComponents from "../../components/sensors/sensoresComponent";
import WeatherComponent from "../../components/weather/weather.component";
import '../../styles/pages/sensors/sensors.scss'

function SensorsPage() {
  return (
    <div className="sensors">
      <HeaderComponent />

      <div className="grid5">
        <WeatherComponent />
      </div>

      <div className="grid5">
        <SensorComponents />
      </div>
      
    </div>
  );
}

export default SensorsPage;
