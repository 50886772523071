import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AiCam from "../pages/ai/aiCam";
import AuthPage from "../pages/auth/authBack";
import AuthPageRouter from "../pages/auth/authPage";
import LabDashBoard from "../pages/lab/lab.dashboard";
import DashPage from "../pages/main/dashboardPage";
import SettingsPage from "../pages/main/settingsPages";
import SensorsPage from "../pages/sensors/sensorsPage";
import StatusPage from "../pages/status/statusPage";

function Routes() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div style={{ color: "#000", textAlign: "center" }}>
            Carregando...
          </div>
        }
      >
        <Switch>
          <Route path="/" component={AuthPageRouter} exact />
          <Route path="/dashboard" component={DashPage} />
          <Route path="/sensors" component={SensorsPage} />
          <Route path="/iacam" component={AiCam} />
          <Route path="/authback/:token" component={AuthPage} />
          <Route path="/lab" component={LabDashBoard} />
          <Route path="/status" component={StatusPage} />

          <Route path="/settings" component={SettingsPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
