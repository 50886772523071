import { AiOutlineDatabase } from "react-icons/ai";
import { SiFlattr } from "react-icons/si";
import { useEffect, useState } from "react";
import apiMain from "../../utils/api";

function ServerStatusComponent() {
  const [latency, setLatency] = useState([]);

  useEffect(() => {
    apiMain.get("app/global/info/server").then((response) => {
      setLatency(response.data);
    });
  }, []);

  return (
    <div className="card-M light">
      <div className="column">
        <div>
          <AiOutlineDatabase size={30} /> Latência
        </div>
        <div />
      </div>
      {latency.map(({ latency, name }) => {
        return (
          <div>
            {name} | {latency}ms <SiFlattr size={14} />
          </div>
        );
      })}
    </div>
  );
}

export default ServerStatusComponent;
