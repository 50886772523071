import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import PinMarker from '../../sources/images/pinMaker.png'
import CompanyMarker from '../../sources/images/company.png'

function MapComponent() {

  const [yourLocal, setYourLocal] = useState({
    latitude: 0,
    longitude: 0
  })

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position) {

        setYourLocal({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      }
    });
  }, []);

  const IconMarkerL = new L.Icon({
    iconUrl: PinMarker,
    iconSize: [60, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const IconMarkerZ = new L.Icon({
    iconUrl: CompanyMarker,
    iconSize: [60, 61],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });



  return (
    <div className="card-E nopadding">
      <MapContainer
        doubleClickZoom={false}
        zoom={10}
        center={[-23.13447, -45.7608717492084]}
        style={{ width: "100%", height: "35rem" }}
      >
        <Marker
          position={[yourLocal.latitude, yourLocal.longitude]}
          key={yourLocal.latitude}
          icon={IconMarkerL}
        >
          <Popup>Você</Popup>
        </Marker>

        <Marker
          position={[-22.900124,-45.3666763]}
          key={yourLocal.latitude}
          icon={IconMarkerZ}
        >
          <Popup>Empresa</Popup>
        </Marker>
        <TileLayer url="https://api.mapbox.com/styles/v1/gutierrezh/ckw2siigi16f514o4cbbjpvby/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3V0aWVycmV6aCIsImEiOiJja2c3aWZ3cXMwMTd6MnpxcjVwaW13ZTF5In0.0dHORWAerMrfK5999uzk9Q" />
      </MapContainer>
    </div>
  );
}

export default MapComponent;
