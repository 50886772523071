import '../../styles/components/popup.scss'

interface popupInterface{
    children?: any;
    widthProps?: string;
}

const PopupComponent: React.FC<popupInterface> = ({ children, widthProps}) => {
    return(
        <div className="grey">
            <div className="box" style={widthProps ? {width: widthProps} : {}}>
                {children}
            </div>
        </div>
    )
}

export default PopupComponent;