import "../../styles/components/header.scss";
import HeaderComponent from "../../components/global/header";
import CamBoxComponent from "../../components/cam/cambox";

function AiCam() {
  return (
    <div id="Auth_Page">

      <HeaderComponent />

      <div className="grid3-mix">
        <CamBoxComponent name="Camera Rua 1" url="https://solucoes.me/video/humans.mp4" />
        <CamBoxComponent name="Camera Industrial" url="https://solucoes.me/video/industry.mp4"/>
        <CamBoxComponent name="Camera Reunião" url="https://solucoes.me/video/meeting.mp4"/>
        <CamBoxComponent name="Camera Reunião" url="https://solucoes.me/video/audi.mp4"/>
      </div>


    </div>
  );
}

export default AiCam;
