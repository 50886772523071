import { TiWeatherSunny } from "react-icons/ti";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCloudSun } from "react-icons/bs";
import { useEffect, useState } from "react";
import apiMain from "../../utils/api";
import { FaArrowAltCircleUp, FaArrowCircleDown } from "react-icons/fa";

interface weather {
  weather: [
    {
      description: string;
    }
  ];
  main: {
    temp: number;
    temp_min: number;
    temp_max: number;
    humidity: number;
    sea_level: number;
  };
  name: string;
}

function WeatherComponent() {
  const [weather, setWeather] = useState<weather>();

  useEffect(() => {
    apiMain.get("app/global/info/weather").then((response) => {
      setWeather(response.data);
    });
  }, []);

  return (
    <div className="card-M">
      {weather ? (
        <>
          <div className="column">
            <div>
              <TiWeatherSunny size={30} /> {weather?.name}
            </div>
            <div />
          </div>
          <div className="column">
            <div>
              <AiOutlineInfoCircle size={24} />
              {weather?.weather.map(({ description }) => {
                return <> {description} <br /> </>;
              })}
            </div>
            <div>
              <BsCloudSun size={24} />{" "}
              {(weather?.main.temp - 273.15).toFixed(2)} º C
            </div>
            <div>
            <FaArrowCircleDown size={24} /> {(weather?.main.temp_min - 273.15).toFixed(2)} º C 
            </div>
            <div>
            <FaArrowAltCircleUp size={24} /> {(weather?.main.temp_max - 273.15).toFixed(2)} º C
            </div>
          </div>{" "}
        </>
      ) : null}
    </div>
  );
}

export default WeatherComponent;
