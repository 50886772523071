import { AiTwotoneSecurityScan } from "react-icons/ai";
import { useState } from "react";

function IpSecurityComponent() {
  const [ip, setIp] = useState("");

  const publicIp = require("public-ip");
  (async () => {
    setIp(await publicIp.v4());
  })();


  return (
    <div className="card-M light">
      <div className="column">
        <div>
          <AiTwotoneSecurityScan size={30} /> Segurança
        </div>
        <div />
      </div>
      <div> Seu ip é: {ip} </div>
    </div>
  );
}

export default IpSecurityComponent;
