import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import apiMain from "../../utils/api";
import "../../styles/pages/login.scss";
import { FaIndustry } from "react-icons/fa";

interface AuthBack {
  token: string;
}

export default function AuthPage() {
  const params = useParams<AuthBack>();
  const history = useHistory();
  useEffect(() => {
    if (params.token) {
      setTimeout(() => {
        apiMain
          .post("/auth/validate", {
            token: params.token,
          })
          .then((response) => {
            localStorage.setItem("@blue", String(response.data.token));
            history.push("/dashboard");
          });
      }, 5000);
    }
  }, [history, params.token]);

  return (
    <div id="Auth_Page">
      <div className="auth" style={{display: 'flex'}}>

        <div className="authGo authTransitionH">

        <br />
        <br />
        <br />
        <br />
        <br />

        <p>
        <FaIndustry size={140} color="white" />
        <br />
          Loading ICPlataform ...
        </p>
        </div>
      </div>
    </div>
  );
}
