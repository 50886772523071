import React from "react";
import "./styles/pages/login.scss";
import Routes from "./routers/router";
import './index.scss';
import './styles/components/cards.scss';
import './styles/components/inputs.scss';

function App() {
  return (
    <Routes />
  );
}

export default App;
