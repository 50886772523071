import { useEffect, useState } from "react";
import "../../styles/components/header.scss";
import apiMain from "../../utils/api";
import PopupComponent from "./popup";
import { CgDanger } from "react-icons/cg";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ProfileDefault from '../../sources/images/profiledefault.png'

const HeaderComponent: React.FC = () => {
  const [name, setName] = useState("Loading...");
  const [photo, setPhoto] = useState(ProfileDefault);
  const [logout, setLogout] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      apiMain
        .post("/auth/verify", {
          token: localStorage.getItem("@blue"),
        })
        .then((response) => {
          setName(response.data.name);
          setPhoto(response.data.photo);
        });
    }, 3000);
  }, []);

  function logoutAccount() {
    localStorage.removeItem("@blue");
    history.push("/");
  }

  return (
    <>
      {logout ? (
        <PopupComponent
          children={
            <>
              <div>
                {" "}
                <CgDanger size={30} />
                Você deseja realmente sair?
              </div>
              <div>
                <button
                  onClick={() => {
                    logoutAccount();
                  }}
                >
                  Sim
                </button>
                <button className="red" onClick={() => setLogout(false)}>
                  Não
                </button>
              </div>
            </>
          }
        />
      ) : null}
      <div className="H_Header">
        <div className="logo">IC Plataform |</div>
        <div className="link">
          <Link to="/dashboard">Inicio</Link>
          <Link to="/sensors">Sensores</Link>
          <Link to="/iacam">IACam</Link>
          <Link to="/lab">Lab</Link>
          <Link to="/status">System Status</Link>
        </div>
        <div className="space">
          <div>
            {" "}
            {photo && (
              <img src={photo} className="card-R-img" alt={name} />
            )}{" "}
            {name} |
          </div>
          <div className="logout NoSelect" onClick={() => setLogout(true)}>
            Sair
          </div>
          <div>  <Link to="/settings">Configurações</Link></div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
