import "../../styles/components/header.scss";
import HeaderComponent from "../../components/global/header";
import WeatherComponent from "../../components/weather/weather.component";
import ServerStatusComponent from "../../components/weather/serverStatus.component";
import IpSecurityComponent from "../../components/weather/ipSecurity.component";
import MapComponent from "../../components/weather/map.component";
import DistanceToWorkComponent from "../../components/weather/distancetoWork";
import NewsListComponent from "../../components/weather/newslist";
import CamBoxComponent from "../../components/cam/cambox";

function DashPage() {
  return (
    <div id="Auth_Page">

      <HeaderComponent />

      <div className="grid5">
        <WeatherComponent />
        <ServerStatusComponent />
        <IpSecurityComponent />
        <DistanceToWorkComponent />
        <NewsListComponent />
      </div>

      <div className="grid2-1">
        <MapComponent />
        <CamBoxComponent name="Camera Reunião" url="https://solucoes.me/video/meeting.mp4"/>
      </div>
    </div>
  );
}

export default DashPage;
