import { BsNewspaper } from "react-icons/bs";
import { useEffect, useState } from "react";
import apiMain from "../../utils/api";

function NewsListComponent() {
  
  const [newsTitle, setNewsTitle] = useState("");
  const [genre, setGenre] = useState("");

  useEffect(() => {
    apiMain.get("http://servicodados.ibge.gov.br/api/v3/noticias/?tipo=noticia").then((response) => {
      setNewsTitle(response.data.items[0].titulo);
      setGenre(response.data.items[0].editorias)
    });
  }, []);

  return (
    <div className="card-M">
      <div className="column">
        <div>
          <BsNewspaper size={30} /> Noticia
        </div>
        <div />
      </div>
      <div>{newsTitle}</div>
      <div>Genero: {genre}</div>
    </div>
  );
}

export default NewsListComponent;
