import { useEffect, useState } from "react";
import { FaTemperatureHigh } from "react-icons/fa";
import { RiTempColdLine } from "react-icons/ri";
import socketIOClient from "socket.io-client";

const ENDPOINT = "https://sp01.br.services.congrebio.solucoes.me:4049";

const socket = socketIOClient(ENDPOINT, {
  auth: {
    token: localStorage.getItem("@blue"),
  },
});

socket.on("connection", () => {
  console.log("Conectado");
});
const SensorComponents: React.FC = () => {
  const [sensor, setSensor] = useState({
    created: new Date(),
    humidity: 0,
    name: "",
    temperature: 0,
    url: "",
  });

  useEffect(() => {
    socket.on("esp8266", (data) => {
      setSensor({
        created: new Date(data.data.created),
        humidity: data.data.humidity,
        name: data.data.name,
        temperature: data.data.temperature,
        url: data.data.url,
      });
    });
  }, []);

  return (
    <div className="card-M listSensors">
      <p>{sensor.name}</p>

      <img src={sensor.url} alt={sensor.name} style={{ width: "60%" }} />

      <div className="details">
        <FaTemperatureHigh size={30} /> {sensor.temperature}ºC
        <RiTempColdLine size={30} /> {sensor.humidity}%
        
      </div>
        <h6>Up: {sensor.created.toLocaleString()} </h6>
    </div>
  );
};

export default SensorComponents;
