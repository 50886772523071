import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineLocalLibrary,
} from "react-icons/md";
import "../../styles/components/header.scss";
import HeaderComponent from "../../components/global/header";
import { BsAppIndicator, BsPeople } from "react-icons/bs";
import { AiTwotoneSecurityScan } from "react-icons/ai";
import apiMain from "../../utils/api";
import cogoToast from "cogo-toast";
import PopupComponent from "../../components/global/popup";

function SettingsPage() {
  const [ip, setIp] = useState("");

  const publicIp = require("public-ip");
  (async () => {
    setIp(await publicIp.v4());
  })();

  const [photoTemp, setPhotoTemp] = useState("");
  const [photo, setPhoto] = useState<File[]>([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")

  function uploadNewFile(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const UploadNewFileArray = Array.from(event.target.files);

    setPhoto(UploadNewFileArray);
  }

  function sendPhoto(e: FormEvent) {
    const token = localStorage.getItem("@blue");

    if (token) {
      const data = new FormData();
      data.append("token", token);
      photo.forEach((file) => {
        data.append("file", file);
      });

      apiMain
        .post("photo", data)
        .then((response) => {
          cogoToast.success("Sucesso 😊");
          setPhotoTemp(response.data.url);
          setPhoto([])
        })
        .catch((error) => {
          cogoToast.error("Erro");
        });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      apiMain
        .post("/auth/verify", {
          token: localStorage.getItem("@blue"),
        })
        .then((response) => {
          setPhotoTemp(response.data.photo);
          setEmail(response.data.email)
          setName(response.data.name)
          setPhone(response.data.phone)
        });
    }, 3000);
  }, []);

  return (
    <div id="Auth_Page">
      <HeaderComponent />

      <div className="grid2D3">
        <div className="card-M">
          <div className="decorate">
            <MdOutlineLocalLibrary size={30} />
            <p>Seu IP está sendo gravado {ip}</p>
          </div>
        </div>

        <div className="card-M">
          <div className="decorate">
            <MdOutlineAdminPanelSettings size={30} />
            <p>Use esse recurso para manter a sua conta em segurança.</p>
          </div>
        </div>
      </div>

      <div className="grid2L">
        <div className="card-M light">
          <div className="decorate">
            <BsPeople size={30} /> <p>Configurações Gerais</p>
          </div>
          <div className="personal_Settings">
            <input
              type="email"
              placeholder="Digite seu e-mail"
              className="inputL"
              value={email}
              disabled
            />
            <input type="text" placeholder="Nome completo" className="inputL" value={name} disabled/>
            <input type="number" placeholder="Telefone" className="inputL" value={phone} disabled />
            <input type="password" placeholder="Senha"
            onChange={(e) => {setPassword(e.target.value)}}
            value={password}
            className="inputL" />


            <button>
              SALVAR ALTERAÇÕES
            </button>
          </div>
        </div>

        <div className="card-M">
          <div className="decorate">
            <AiTwotoneSecurityScan size={30} /> Configurações Pessoais
            <div style={{ display: "flex", flexDirection: "column" }}>
            {photoTemp ?  <img src={photoTemp} alt="ok" width="40%" /> : <> Carregando ... </>}
              <input
                multiple
                type="file"
                style={{ visibility: "hidden" }}
                id="imageVerify"
                accept="image/jpeg,image/png"
                onChange={(e) => {
                  uploadNewFile(e);
                }}
              />
              <label htmlFor="imageVerify">ALTERAR</label>
            </div>
          </div>
        </div>
      </div>

      <div className="grid2L">
        <div className="card-M">
          <div className="decorate">
            <BsAppIndicator size={30} /> <p>Aplicação</p>
          </div>
          <div className="personal_Settings">
            <div className="application">IC Plataform - Full Access</div>
          </div>
        </div>
      </div>

      {photo.length > 0 ? (
        <PopupComponent widthProps="auto">
          <div style={{ flexDirection: "column" }}>
            <img
              src={URL.createObjectURL(photo[0])}
              alt="Temp Profile"
              style={{ width: "80%", height: "40rem" }}
            />
            <div>
              <button
                onClick={(e) => {
                  sendPhoto(e);
                }}
              >
                Alterar foto
              </button>
              <button
                className="red"
                onClick={() => {
                  setPhoto([]);
                }}
              >
                Fechar
              </button>
            </div>
          </div>
        </PopupComponent>
      ) : null}
    </div>
  );
}

export default SettingsPage;
