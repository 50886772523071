interface ImgProcProps{
    url: string;
}

const ImageProcessingComponent: React.FC<ImgProcProps> = ({url}) => {
    return(
        <div key={url} className="card-M" style={{padding: 0, textAlign: 'center'}}>
            <h4>List Images</h4>
            <img src={url}
            width="100%" height="100%"
            alt="" />
        </div>
    )
}

export default ImageProcessingComponent;