import { MdOutlineTravelExplore } from "react-icons/md";
import { useEffect, useState } from "react";
import apiMain from "../../utils/api";

function DistanceToWorkComponent() {
  const [distance, setDistance] = useState(0);

  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      apiMain
        .get(
          "auth/ic-plataform/distance",
            {
              params: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            }
        )
        .then((response) => {
          setMinutes(response.data.results[0].travelDuration);
          setDistance(response.data.results[0].travelDistance);
        });
    });
  }, []);

  return (
    <div className="card-M">
      <div className="column">
        <div>
          <MdOutlineTravelExplore size={30} /> Distancia
        </div>
        <div />
      </div>
      <div>Você chegará em {minutes.toFixed(2)} minutos</div>
      <div>Distância é de {distance.toFixed(2)}km</div>
    </div>
  );
}

export default DistanceToWorkComponent;
