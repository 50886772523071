import { ChangeEvent, FormEvent, useState } from "react";
import apiAI from "../../utils/apiia";
import PopupComponent from "../global/popup";

interface Array {
  list: any[];
}

const DetailsProcessingComponent: React.FC<Array> = ({ list }) => {
  console.log(list[0]);
  const [tempFile, setTempFile] = useState<File[]>();
  const [tempUrlImage, setTempUrlImage] = useState("");
  const [tempUrlViewImage, setTempUrlViewImage] = useState("");

  function Upload(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    setTempFile(Array.from(event.target.files));

    if (event.target.files[0])
      setTempUrlImage(URL.createObjectURL(event.target.files[0]));
  }

  function sendFile(e: FormEvent) {
    e.preventDefault();
    const data = new FormData();
    data.append("token", String(localStorage.getItem("@blue")));
    tempFile?.forEach((file) => {
      data.append("file", file);
    });

    apiAI
      .post("app/training", data)
      .then((response) => {
        setTempUrlImage("");
      })
      .catch((error) => {});
  }

  return (
    <>
      <div className="card-M details">
        <p>Details</p>
        <input
          multiple
          type="file"
          id="buttonUploadPhoto"
          accept="image/jpeg,image/png"
          style={{ visibility: "hidden" }}
          onChange={(e) => {
            Upload(e);
          }}
        />
        <label htmlFor="buttonUploadPhoto">ENVIAR FOTO PARA PROCESSAR</label>
        <br />
        {list[0].map((data) => {
          const dataFormat = new Date(data.created);
          return (
            <div>
              <img className="imageZoom"
                key={data.url}
                onClick={() => {
                  setTempUrlViewImage(data.url);
                }}
                src={data.url}
                width="70px"
                alt="Information"
              />
              - {dataFormat.toLocaleString()}
            </div>
          );
        })}

        {tempUrlImage ? (
          <PopupComponent widthProps="auto">
            <div style={{ flexDirection: "column" }}>
              <img
                src={tempUrlImage}
                alt="Temp Profile"
                style={{ width: "80%", height: "40rem" }}
              />
              <div>
                <button
                  onClick={(e) => {
                    sendFile(e);
                  }}
                >
                  Enviar
                </button>
                <button
                  className="red"
                  onClick={() => {
                    setTempUrlImage("");
                  }}
                >
                  Não
                </button>
              </div>
            </div>
          </PopupComponent>
        ) : null}

        {tempUrlViewImage ? (
          <PopupComponent widthProps="auto">
            <div style={{ flexDirection: "column" }}>
              <img
                src={tempUrlViewImage}
                alt="Temp Profile"
                style={{ width: "80%", height: "40rem" }}
              />
              <div>
                <button
                  className="red"
                  onClick={() => {
                    setTempUrlViewImage("");
                  }}
                >
                  Fechar
                </button>
              </div>
            </div>
          </PopupComponent>
        ) : null}
      </div>
    </>
  );
};

export default DetailsProcessingComponent;
