import { GiVibratingBall } from "react-icons/gi";

function PingAndStatus() {


  return (
    <div className="card-M listSensors">
      <p>Status System </p>

      <div style={{flexDirection: "column", alignItems: 'flex-start' }}>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Google</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Portainer 1</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Portainer 2</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Kafka</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Docker</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Nginx</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Apache</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Postgresql</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Confluence</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Zookeper</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> Flask</div>
      <div style={{marginBottom: '.4rem'}}><GiVibratingBall size={30} color="green" /> TensorFlow Docker    </div>
      </div>
    </div>
  );
}

export default PingAndStatus;
